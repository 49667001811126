.organization-tree[data-v-01d354dc] {
  flex: 1;
  width: 100%;
  min-width: 1px;
}
.custom-tree-node-label[data-v-01d354dc] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
