.table[data-v-f87090a7] .el-table__header-wrapper  .el-checkbox {
  display: none;
}
.table[data-v-f87090a7] label {
  margin-bottom: 0;
}
.table[data-v-f87090a7] .el-checkbox__original {
  position: absolute;
}
.body[data-v-f87090a7] {
  display: flex;
  flex-direction: column;
}
